$white: #ffffff;
$black: #000000;
$blue: #0000FF;
$light-blue: #68bbe3;
$light-blue-rgba: rgb(104, 187, 227, 0.7);
$grey: #173F56;
$orange: #FF7A00;
$obscure-orange: #D65211;
$obscure-peach: #F9BE6A;
$light-orange: #FFAF2C;
$peach: #FFD863;
$light-peach: #FDF3C0;


:root {
  --bg-page: #{$white};
  --text-color: #{$black};
  --link-color: #{$blue};
}
