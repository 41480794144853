@use '../settings/font-sizes.scss' as *;
@use '../settings/font-weights.scss' as *;
@use '../tools/responsive.scss' as *;
@use '../settings/colors.scss' as *;

.wrapper{
    scroll-snap-type:y mandatory;
    height: 100vh;
    overflow: auto;
    @media (max-width: $laptop_max) {
        height: 100%;
    }
}
section, footer{
    scroll-snap-align: center;
}
.section-hero {
    padding: 120px 0 0 20px;
    background-color: $light-peach;
    width: 100%;
    min-height: 100vh;
    position: relative;
    @media (max-width: $tablet_max) {
        padding: 60px 0 0 20px;
    }
    @media (max-width: $mobile_md_max) {
        padding: 0;
    }
    @media (max-width: $laptop_max) {
        min-height: 100%;
    }
    &-content{
        display: flex;
        @media (max-width: $tablet_max) {
            flex-direction: column;
        }
        &__left{
            width: 55%;
            padding-top: 100px;
            @media (max-width: $tablet_max) {
            padding-top: 50px;
            width: 80%;
            margin: 0 auto;
            }
        }
        &-title{
            display: flex;
            align-items: center;
            &__img{
                img{
                    max-width: 100%;
                }
            }
            &__text{
                max-width: 66%;
                font-weight: $bold;
                color: $orange;
                font-size: $h1-xxl;
                text-align: center;
                text-transform: uppercase;
                padding-left: 10px;
                margin: 0;
                @media (max-width: $laptop-md_max) {
                    font-size: $h1-xl;
                }
                @media (max-width: $laptop_max) {
                    font-size: $h1-l;
                }
                @media (max-width: $wide_max) {
                    font-size: $h1-m;
                }
                @media (max-width: $tablet_large_max) {
                    font-size: $h1-s;
                }
                @media (max-width: $mobile_md_max) {
                    font-size: $h1-xs;
                }
                @media (max-width: $mobile_max) {
                    font-size: $h1-xxs;
                }
            }
        }
        &__description{
            margin: 50px 0 0 0;
            font-weight: $bold;
            color: $orange;
            width: 80%;
            font-size: $size-xl;
            @media (max-width: $laptop-md_max) {
                font-size: $size-l;
            }
            @media (max-width: $laptop_max) {
                font-size: $size-m;
            }
            @media (max-width: $wide_max) {
                font-size: $size-s;
            }
            @media (max-width: $tablet_large_max) {
                font-size: $size-xs;
            }
            @media (max-width: $tablet_max) {
                padding: 10px 0 20px 0;
                margin: 0 auto;
            }
            @media (max-width: $mobile_md_max) {
                font-size: $size-xxs;
            }
        }
        &__img{
            width: 50%;
            transform: scaleX(-1);
            @media (max-width: $tablet_max) {
                margin: 0 auto;
            }
            img{
                max-width: 550px;
                @media (max-width: $laptop_max) {
                    max-width: 500px;
                }
                @media (max-width: $wide_max) {
                    max-width: 450px;
                }
                @media (max-width: $tablet_large_max) {
                    max-width: 400px;
                }
                @media (max-width: $tablet_max) {
                    max-width: 350px;
                }
                @media (max-width: $mobile_md_max) {
                    max-width: 300px;
                }
                @media (max-width: $mobile_max) {
                    max-width: 250px;
                }
            }
        }
    }
}
