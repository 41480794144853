@use '../settings/font-sizes.scss' as *;
@use '../settings/font-weights.scss' as *;
@use '../tools/responsive.scss' as *;
@use '../settings/colors.scss' as *;

.section-our-help{
    padding: 40px 0 30px 0;
    width: 100%;
    min-height: 100vh;
    background-color: $light-orange;
    @media (max-width: $laptop_max) {
        min-height: 100%;
    }
    &__title{
        h2{
            color: $light-peach;
            border: 3px solid $peach;
            border-radius: 15px;
            letter-spacing: 5px;
            text-align: center;
            max-width: 800px;
            max-height: 200px;
            font-size: $h1-m;
            padding: 5px;
            margin: 0 35px;
            @media (max-width: $tablet_large_max) {
                font-size: $h1-xs;
                max-width: 600px;
            }
            @media (max-width: $tablet_max) {
                font-size: $h1-xxs;
                max-width: 500px;
            }
            @media (max-width: $mobile_md_max) {
                font-size: $size-s;
                max-width: 400px;
            }
            @media (max-width: $mobile_max) {
                font-size: $size-xs;
                max-width: 400px;
            }
        }
    }
    &-conteiner{
        display: grid;
        gap: 15px 45px;
        grid-template-columns: repeat(auto-fit, minmax(310px,1fr));
        justify-items: center;
        @media (max-width: $tablet_max) {
            grid-template-columns: 1fr;
        }
        &-box{
            width: 100%;
            max-width: 310px;
            height: 310px;
            border: 3px solid $peach;
            border-radius: 15px;
            margin: 15px;
            position: relative;
            &__img{
                img{
                    position: absolute;
                    width: 300px;
                    height: 300px;
                }
            }
            &__square{
                position: relative;
                margin: auto;
                width: 10px;
                height: 10px;
                border: 1px solid $white;
                background-color: $white;
                z-index: 3;
            }
            &__text{
                display: flex;
                flex-direction: column;
                justify-content: center;
                background-color: $light-blue-rgba;
                
                text-align: center;
                height: 60%;
                position: absolute;
                top: 20%;
                
                h6{
                    color: $white;
                    font-size: $size-m;
                    margin: 0;
                }
                p{
                    color: $white;
                    font-size: $size-xs;
                    margin: 0;
                }
            }
        }
        &-img{
            img{
                width: 320px;
                height: 320px;
                margin: auto;
            }
        }
    }
}

