$h1-mobile: 24px;
$h2-mobile: 18px;
$h3-mobile: 20px;
$h4-mobile: 18px;
$h5-mobile: 16px;
$h6-mobile: 14px;



$h1-medium: 26px;
$h2-medium: 24px;
$h3-medium: 21px;
$h4-medium: 20px;
$h5-medium: 18px;
$h6-medium: 16px;

$link-size_wide: 14px;

$h-lh: 1.2;
$t-lh: 1.2;
$btn-lh: 1.2;
$li-lh: 2;

$size-xxl: 55px;
$size-xl: 45px;
$size-l: 40px;
$size-m: 35px;
$size-s: 30px;
$size-xs: 25px;
$size-xxs: 20px;

$p-size-xxl: 40px;
$p-size-xl: 32px;
$p-size-l: 30px;
$p-size-m: 25px;
$p-size-s: 20px;
$p-size-xs: 15px;
$p-size-xxs: 10px;

$h1-xxl: 100px;
$h1-xl: 90px;
$h1-l: 80px;
$h1-m: 70px;
$h1-s: 60px;
$h1-xs: 50px;
$h1-xxs: 40px;