@use '../settings/font-sizes.scss' as *;
@use '../settings/font-weights.scss' as *;
@use '../tools/responsive.scss' as *;
@use '../settings/colors.scss' as *;

.section-services {
    padding: 40px 0 30px 0;
    width: 100%;
    min-height: 100vh;
    background-color: $peach;
    @media (max-width: $laptop_max) {
        min-height: 100%;
    }
    &__title{
        h2{
            font-size: $h1-m;
            color: $black;
            border: 3px solid $black;
            border-radius: 15px;
            letter-spacing: 5px;
            text-align: center;
            padding: 20px 2px 20px 2px;
            @media (max-width: $laptop_max) {
                font-size: $size-l;
                padding: 12px 2px 12px 2px;
            }
            @media (max-width: $tablet_large_max) {
                font-size: $size-s;
                padding: 10px 2px 10px 2px;
            }
            @media (max-width: $tablet_max) {
                font-size: $size-xs;
                padding: 10px 2px 10px 2px;
            }
            @media (max-width: $mobile_md_max) {
                font-size: $size-xxs;
            }
            
        }
    }
    &__conteiner{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @media (max-width: $tablet_max) {
            display: block;
        }
    }
    &-item{
        width: 45%;
        padding: 15px;
        @media (max-width: $tablet_max) {
            width: 100%;
            margin: auto;
        }
        &__header{
            background-color: $light-peach;
            max-width: 350px;
            height: 100px;
            border: 2px solid $black;
            border-radius: 20px;
            box-shadow: 5px 5px 5px;
            text-align: center;
            margin: 0 auto;
            padding-top: 30px;
            h3{
                margin: 5px;
                font-size: $size-xs;
            }
        }
        .two-string{
            padding-top: 16px;
        }
        &__body{
            margin: 40px 5px;
            padding: 30px;
            background-color: $white;
            border: 2px solid $grey;
            h4{
                text-align: center;
                font-size: $size-s;
                margin: 0;
                padding-top: 5px;
            }
        }
        &__list{
            padding: 0px;
            margin: 0;
            li{
                font-size: $h3-medium;
                line-height: $li-lh;
                font-weight: $bold;
                list-style-type: none;
                &:before{
                    content: '✔️';
                    padding-right: 5px;
                }
            }
            
        }
        &__footer{
            margin: 0 auto;
            width: 60%;
            height: 70px;
            
            background-color: $light-blue;
            border: 5px solid $peach;
            outline: 5px solid $light-blue;
            border-radius: 50px;
            h3{
                color: $white;
                font-size: $size-m;
                text-align: center;
                padding: 10px;
                @media (max-width: $mobile_md_max) {
                    font-size: $size-s;
                }
                @media (max-width: $mobile_max) {
                    font-size: $size-xs;
                    padding: 15px;
                }
            }
        }
    }
}





