@use '../settings/font-sizes.scss' as *;
@use '../settings/font-weights.scss' as *;
@use '../tools/responsive.scss' as *;
@use '../settings/colors.scss' as *;

.footer {
    padding: 40px 0 30px 0;
    width: 100%;
    min-height: 100vh;
    background-color: $light-peach;
    @media (max-width: $laptop_max) {
        min-height: 100%;
    }
    &__title{
        h2{
            margin: auto;
            font-size: $size-xxl;
            max-width: 700px;
            padding: 20px 0;
            color: $light-peach;
            background-color: $obscure-orange;
            border-radius: 60px;
            letter-spacing: 5px;
            text-align: center;
            @media (max-width: $laptop-md_max) {
                font-size: $size-xl;
                padding: 15px 2px 15px 2px;
            }
            @media (max-width: $laptop_max) {
                max-width: 600px;
                font-size: $size-l;
                padding: 12px 2px 12px 2px;
            }
            @media (max-width: $tablet_large_max) {
                max-width: 450px;
                font-size: $size-m;
            }
            @media (max-width: $tablet_max) {
                max-width: 390px;
                font-size: $size-s;
            }
            @media (max-width: $mobile_md_max) {
                font-size: $size-xs;
                max-width: 350px;
            }
        }
    }
    &__mail{
        margin: 100px 0 50px 0px;
        h3{
            text-align: center;
            font-size: $h1-s;
            color: $grey;
            @media (max-width: $laptop_max) {
                font-size: $h1-xs;
            }
            @media (max-width: $tablet_large_max) {
                font-size: $h1-xxs;
            }
            @media (max-width: $tablet_max) {
                font-size: $size-s;
            }
            @media (max-width: $mobile_md_max) {
                font-size: $size-xs;
            }
        }
        @media (max-width: $tablet_large_max) {
            margin: 70px 0 30px 0px;
        }
        @media (max-width: $tablet_max) {
            margin: 50px 0 15px 0px;
        }
    }
    &-links{
        display: flex;
        justify-content: center;
        align-items: flex-end;
        max-width: 1400px;
        &__img{
            img{
                height: 400px;
                @media (max-width: $laptop_max) {
                    height: 350px;
                }
                @media (max-width: $tablet_large_max) {
                    height: 250px;
                }
                @media (max-width: $tablet_max) {
                    max-height: 200px;
                    height: 100%;
                }
                @media (max-width: $mobile_md_max) {
                    
                }
            }
        }
        &__social{
            display: flex;
            justify-content: space-between;
            flex: 80% 0 1;
            padding: 10px;
            @media (max-width: $mobile_md_max) {
                max-width: 250px;
                flex-wrap: wrap;
                justify-content: space-evenly;
                flex: 87% 0 1;
            }
        }
        &__icon{
            img{
                max-height: 128px;
                @media (max-width: $laptop_max) {
                    height: 100px;
                }
                @media (max-width: $tablet_large_max) {
                    height: 80px;
                }
                @media (max-width: $tablet_max) {
                    height: 60px;
                }
                @media (max-width: $mobile_md_max) {
                    padding: 3px;
                }
                @media (max-width: $mobile_max) {
                    height: 55px;
                }
            }
        }
    }
}





