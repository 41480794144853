@use '../settings/font-sizes.scss' as *;
@use '../settings/font-weights.scss' as *;
@use '../tools/responsive.scss' as *;
@use '../settings/colors.scss' as *;

.section-about {
    padding-top: 60px;
    width: 100%;
    min-height: 100vh;
    background-color: $light-peach;
    @media (max-width: $wide_max) {
        padding-top: 40px;
        min-height: 100%;
    }
    @media (max-width: $laptop_max) {
        min-height: 100%;
    }
    &__head{
        h2{
            color: $grey;
            border: 3px solid $grey;
            border-radius: 15px;
            letter-spacing: 5px;
            text-align: center;
            padding: 20px 2px 20px 2px;
            font-size: $h1-s;
            @media (max-width: $laptop-md_max) {
                font-size: $size-xxl;
                padding: 15px 2px 15px 2px;
            }
            @media (max-width: $laptop_max) {
                font-size: $size-xl;
                padding: 12px 2px 12px 2px;
            }
            @media (max-width: $wide_max) {
                font-size: $size-l;
                padding: 10px 2px 10px 2px;
            }
            @media (max-width: $tablet_large_max) {
                font-size: $size-s;
                padding: 10px 2px 10px 2px;
            }
            @media (max-width: $tablet_max) {
                font-size: $size-xs;
                padding: 10px 2px 10px 2px;
            }
            @media (max-width: $mobile_md_max) {
                font-size: $size-xxs;
            }
        }
    }
    &__body{
        display: flex;
        position: relative;
        @media (max-width: $tablet_max) {
        flex-direction: column;
        }
    }
    &__img{
        max-width:  70vh;
        position: absolute;
        top: 35%;
        left: 2.5%;
        @media (max-width: $laptop-md_max) {
            max-width:  600px;
        }
        @media (max-width: $laptop_max) {
            max-width:  500px;
            position: static;
        }
        @media (max-width: $wide_max) {
            max-width:  400px;
        }
        @media (max-width: $tablet_large_max) {
            max-width:  350px;
            top: 45%;
        }
        @media (max-width: $tablet_max) {
            margin: 0 auto;
            order: 2;
        }
    }
    &__text{
        margin-left: auto;
        width: 70%;
        position: relative;
        right: 0;
        @media (max-width: $tablet_max) {
            width: 100%;
            margin: 0 auto;
            order: 1;
        }
        p{
            line-height: 1.5;
            word-spacing: 15px;
            font-weight: $semibold;
            text-align: justify;
            font-size: $p-size-xl;
            color: $grey;
            padding: 20px;
            @media (max-width: $laptop-md_max) {
                font-size: $p-size-l;
                padding: 15px;
            }
            @media (max-width: $laptop_max) {
                font-size: $p-size-m;
                padding: 10px;
            }
            @media (max-width: $wide_max) {
                font-size: $p-size-s;
                padding: 5px;
            }
            @media (max-width: $tablet_max) {
                word-spacing: 5px;
            }
        }
    }
}
