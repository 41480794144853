@font-face {
    font-family: 'icomoon';
    src:  url('../icons/icomoon.eot?ys19nx');
    src:  url('../icons/icomoon.eot?ys19nx#iefix') format('embedded-opentype'),
      url('../icons/icomoon.ttf?ys19nx') format('truetype'),
      url('../icons/icomoon.woff?ys19nx') format('woff'),
      url('../icons/icomoon.svg?ys19nx#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-instagram:before {
    content: "\e900";
  }
  .icon-facebook:before {
    content: "\e901";
  }
  .icon-behance:before {
    content: "\e902";
  }
  .icon-linkedin:before {
    content: "\e903";
  }
  