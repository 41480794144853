@use '../settings/font-sizes.scss' as *;
@use '../settings/font-weights.scss' as *;
@use '../tools/responsive.scss' as *;
@use '../settings/colors.scss' as *;

.section-feedback {
  padding: 40px 0 30px 0;
  width: 100%;
  min-height: 100vh;
  background-color: $obscure-peach;
  @media (max-width: $laptop_max) {
    min-height: 100%;
  }
  &__title {
    h2 {
      color: $grey;
      border: 3px solid $grey;
      border-radius: 15px;
      letter-spacing: 5px;
      text-align: center;
      max-width: 800px;
      max-height: 200px;
      font-size: $h1-m;
      padding: 5px;
      margin: 0 35px;
      @media (max-width: $tablet_large_max) {
        font-size: $h1-xs;
        max-width: 600px;
      }
      @media (max-width: $tablet_max) {
        font-size: $h1-xxs;
        max-width: 500px;
      }
      @media (max-width: $mobile_md_max) {
        font-size: $size-s;
        max-width: 400px;
      }
    }
  }
  &-phone {
    max-width: 1100px;
    max-height: 700px;
    margin: 70px auto;
    background: url('/src/images/IPhone_14_Pro_vector.svg.png') center / contain
      no-repeat;
    @media (max-width: $wide_max) {
      max-width: 900px;
    }
    @media (max-width: $tablet_large_max) {
      max-width: 727px;
    }
    @media (max-width: $tablet_max) {
      max-width: 535px;
    }
    @media (max-width: $mobile_md_max) {
      background: none;
    }
    &__body {
      max-width: 910px;
      max-height: 600px;
      margin: auto;
      padding: 28px 0 20px 0;
      overflow: hidden;
      @media (max-width: $wide_max) {
        max-width: 750px;
        padding: 21px 0 14px 0;
      }
      @media (max-width: $tablet_large_max) {
        max-width: 600px;
        padding: 20px 0 11px 0;
      }
      @media (max-width: $tablet_max) {
        max-width: 440px;
        padding: 15px 0 6px 0;
      }
    }
  }
}

.swiper {
  min-width: 0;
  &-slide {
    iframe {
      min-width: 0;
      max-width: 100%;
      max-height: 100%;
      height: 480px;

      @media (max-width: $wide_max) {
        height: 400px;
        padding: 5px 0;
      }
      @media (max-width: $tablet_large_max) {
        max-height: 320px;
      }
      @media (max-width: $tablet_max) {
        max-height: 235px;
      }
      @media (max-width: $mobile_md_max) {
        margin-bottom: 30px;
      }
    }
  }
  &-pagination-bullet {
    width: 15px;
    height: 15px;
    background-color: $black;

    @media (min-width: $mobile_md) {
      background-color: $white;
    }
  }
  &-button-prev,
  &-button-next {
    color: $obscure-peach;
    width: 50px;
  }
  &-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
    // max-width: 80px;
    // left: 50%;
    // transform: translate(-50%);
    padding-left: 60px;
    padding-right: 60px;
  }
  .swiper-button-prev {
    left: 0px;

    @media (min-width: $mobile_md) {
      //   left: -40px;
    }
  }

  .swiper-button-next {
    right: 0px;

    @media (min-width: $mobile_md) {
      //   right: -40px;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    @media (max-width: $mobile_md_max) {
      top: auto;
      bottom: 0;
      color: $black;
      width: 30px;
    }

    &::after {
      @media (max-width: $mobile_md_max) {
        font-size: 30px;
      }
    }
  }
}
