@use '../settings/font-sizes.scss' as *;
@use '../settings/font-weights.scss' as *;
@use '../tools/responsive.scss' as *;
@use '../settings/colors.scss' as *;

.section-offer{
    padding-top: 60px;
    width: 100%;
    min-height: 100vh;
    background-color: $peach;
    position: relative;
    @media (max-width: $laptop_max) {
        min-height: 100%;
    }
    p {
        color: $grey;
    }
    &__title{
        h2{
            color: $grey;
            border: 3px solid $grey;
            border-radius: 15px;
            font-size: $size-xxl;
            letter-spacing: 5px;
            text-align: center;
            padding: 20px 2px 20px 2px;
            @media (max-width: $laptop-md_max) {
                font-size: $size-xl;
                padding: 15px 2px 15px 2px;
            }
            @media (max-width: $laptop_max) {
                font-size: $size-l;
                padding: 12px 2px 12px 2px;
            }
            @media (max-width: $wide_max) {
                font-size: $size-m;
                padding: 10px 2px 10px 2px;
            }
            @media (max-width: $tablet_large_max) {
                font-size: $size-s;
                padding: 10px 2px 10px 2px;
            }
            @media (max-width: $tablet_max) {
                font-size: $size-xs;
                padding: 10px 2px 10px 2px;
            }
            @media (max-width: $mobile_md_max) {
                font-size: $size-xxs;
            }
        }
    }
    &__body{
        display: flex;
        @media (max-width: $tablet_max) {
            display: block;
        }
    }
    &-text{
        line-height: 1.5;
        word-spacing: 10px;
        font-weight: $semibold;
        font-size: $p-size-xl;
        color: $grey;
        padding: 20px;
        @media (max-width: $laptop-md_max) {
            font-size: $p-size-l;
            padding: 15px;
        }
        @media (max-width: $laptop_max) {
            font-size: $p-size-m;
            padding: 10px;
        }
        @media (max-width: $wide_max) {
            font-size: $p-size-s;
            padding: 5px;
        }
        @media (max-width: $tablet_max) {
            word-spacing: 5px;
            margin: 0 auto;
        }
    }
    &-left{
        width: 27%;
        padding-top: 4%;
        img{
            max-width: 100%;
            @media (max-width: $tablet_max) {
            display: none;
            }
        }
    }
    &-right{
        width: 73%;
        @media (max-width: $tablet_max) {
            margin: 0 auto;
            width: 100%;
        }
        &__top{
            display: flex;
            @media (max-width: $tablet_max) {
                display: block;
            }
        }
        &__text{
            text-align: center;
            width: 65%;
            @media (max-width: $tablet_max) {
                text-align: justify;
                margin: 0 auto;
                width: 100%;
            }
        }
        &__img{
            max-width: 320px;
            img{
                @media (max-width: $tablet_max) {
                margin: 0 auto;
                }
            }
        }
        &__bottom{
            text-align: right;
            padding-bottom: 10px;
            @media (max-width: $tablet_max) {
                text-align: justify;
            }
        }
    }
}
