@use '../settings/font-sizes.scss' as *;
@use '../settings/font-weights.scss' as *;
@use '../tools/responsive.scss' as *;
@use '../settings/colors.scss' as *;

.section-greetings {
    position: relative;
    padding-top: 60px;
    width: 100%;
    min-height: 100vh;
    background-color: $peach;
    @media (max-width: $wide_max) {
        padding-top: 40px;
    }
    @media (max-width: $laptop_max) {
        min-height: 100%;
    }
    &__title{
        h2{
            color: $grey;
            border: 3px solid $grey;
            border-radius: 15px;
            font-size: $size-xxl;
            letter-spacing: 5px;
            text-align: center;
            padding: 20px 2px 20px 2px;
            @media (max-width: $laptop-md_max) {
                font-size: $size-xl;
                padding: 15px 2px 15px 2px;
            }
            @media (max-width: $laptop_max) {
                font-size: $size-l;
                padding: 12px 2px 12px 2px;
            }
            @media (max-width: $wide_max) {
                font-size: $size-m;
                padding: 10px 2px 10px 2px;
            }
            @media (max-width: $tablet_large_max) {
                font-size: $size-s;
                padding: 10px 2px 10px 2px;
            }
            @media (max-width: $tablet_max) {
                font-size: $size-xs;
                padding: 10px 2px 10px 2px;
            }
            @media (max-width: $mobile_md_max) {
                font-size: $size-xxs;
            }
        }
    }
    &__about{
        p {
            color: $grey;
            font-size: $p-size-xxl;
            font-weight: $bold;
            text-align: center;
            padding: 20px 2px 20px 2px;
            @media (max-width: $laptop-md_max) {
                font-size: $p-size-xl;
            }
            @media (max-width: $laptop_max) {
                font-size: $p-size-l;
            }
            @media (max-width: $wide_max) {
                font-size: $p-size-m;
            }
            @media (max-width: $tablet_large_max) {
                font-size: $p-size-s;
            }
            @media (max-width: $tablet_max) {
                font-size: $p-size-xs;
            }
            
        }
    }
    &__img{
        max-width: 35vh;
        position: absolute;
        right: 0;
        bottom: 0;
        object-fit: cover;
        
        @media (max-width: $laptop_max) {
            position: static;
            margin-left: auto;
        }
        @media (max-width: $wide_max) {
            max-width: 320px;
        }
        @media (max-width: $tablet_large_max) {
            
            max-width: 300px;
        }
    }
}
